#BotswanaTourismApp {
  .iconGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw;
  }

  .iconButton {
    width: clamp(80px, 8vw, 8vw) !important;
    height: clamp(80px, 8vw, 8vw) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;
    /* background-color: #f8ae09; */
    margin-bottom: 5px;
  }

  .icons {
    width: clamp(80px, 8vw, 8vw) !important;
    height: clamp(80px, 8vw, 8vw) !important;
    color: white;
    /* background-color: #f8ae09; */
    border-radius: 50%;
    padding: 1vw;
    margin-bottom: 1vw;
  }

  .iconCaptions {
    font-size: 14px;
  }

  .iconRow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .iconGroup p {
    text-align: center;
    color: black;
  }

  .homeImage {
    width: 50%;
    margin: auto;
  }

  .homeCard {
    width: 18rem;
    border: 1px solid white;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    line-height: 30px;
  }

  .card-text{
    line-height: 30px;
  }
}