.imagesviewer-container {
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
  height: 150px;
  flex-shrink: 0;
  margin-bottom: 15px;
  border: 1px solid rgba(0,0,0,.125);
  padding: 12px; 
  border-radius: 4px;

  @media only screen and (min-width: 800px) {
    height: 200px;
    margin-bottom: 30px;
  }
}

.imagesviewer-previewimage {
  cursor: pointer;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 100% !important;
  margin-right: 2px !important;
  @media only screen and (min-width: 800px) {
    margin-right: 4px !important;
  }
  @media only screen and (min-width: 1400px) {
    flex-basis: 33.33% !important;
  }
}

.imagesviewer-cancel-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  background-size: 30px;
  height: 30px;
  width: 30px;
  z-index: 10;
  cursor: pointer;
}

.imageupload-container {
  border: 1px dashed #DEE2E6;
  border-radius: 5px;
  width: 100%;
  height: 150px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  @media only screen and (min-width: 800px) {
    border: 2px dashed #DEE2E6;
    height: 200px;
  }
}

.imageupload-camera-icon {
  margin-bottom: 15px;
  height: 15px;
  @media only screen and (min-width: 800px) {
    margin-bottom: 30px;
    height: 26px;
  }
}

.imageupload-text {
  font: normal normal normal 14px/21px Gotham;
  color: #B2BCC1;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 20px/26px Gotham;
  }
}

.iamgeupload-input-text {
  font: normal normal normal 14px/21px Roboto;
  color: #CB641E;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0;
  position: relative;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 20px/26px Roboto;
  }
  &:hover {
    text-decoration: underline;
  }
}

.imageupload-input {
  width: inherit;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.imageupload-error {
  font: normal normal normal 10px/24px Gotham;
  color: #D8000C;
  margin: 0;
  position: relative;
  top: -15px;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/24px Gotham;
    top: -30px;
  }
}
