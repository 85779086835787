#ServestISApp {
  .iconGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw;
  }

  .iconRow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 48px;
    background-color: #ffffff;
    padding: 24px;
    border: 1px solid #aaaaae;
    border-radius: 4px;
  }

  .ui.link.card {
    height: 100%;

    .header {
      font-size: large;
    }

    .description {
      color: black;
      font-size: medium;
    }
  }
}
