@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');

#App {
  margin: 2vw;
}

#AppContainer {
  padding-bottom: 14vh;
}

%fullCover {
  height: 100%;
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#ServestISApp {

  &.withBackground { 
    &Img {
      @extend %fullCover;
      background: #F2F2F2 !important;
    }
  }
}

#KslApp {

  &.withBackground { 
    &Img {
      @extend %fullCover;
      background: #F2F2F2 !important;
    }
  }
}

#SSGFacilitiesApp { 
  #App {
    margin: 0px;
    width: 100%;
    height: fit-content;
    max-height: 100vh;
    overflow-y: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 24px;

    .table-data-grid-container {
      height: calc(100vh - 140px) !important;
    }
  }
}

#ServestITApp, #ServestISApp, #BedworthApp, #KslApp {
  %halfCover {
    height: 100%;
    background: no-repeat left center fixed;
    background-size: 0% 100%;
    @media only screen and (min-width: 995px) {
      background-size: 50% 100%;
    }
  }

  &.withBackground {
    &Img {
      @extend %fullCover;
      background: {
        image: url("../img/servest_it/background1.jpg");
        size: 120%;
        repeat: no-repeat;
      }
    }
     &ImgLanding {
       @extend %halfCover;
       overflow-y: auto;
       height: 100%;
       background: {
         image: url("../img/servest_it/pexels-jopwell.jpg");
         repeat: no-repeat;
         size: 50% 100%;
       }
       @media only screen and (max-width: 995px) {
        background-size: cover;
        color: $lightOffGrey;
       }
     }
  }

}

#SyntellApp {
  &.withBackground {
    &Img {
      @extend %fullCover;
      background: {
        image: url("../img/syntell/background.jpeg");
        size: cover;
        repeat: no-repeat;
      }
    }
  }
  &.withBackgroundHome {
    &Img {
      @extend %fullCover;
      background: {
        image: url("../img/syntell/background.jpeg");
        size: cover;
        repeat: no-repeat;
      }
      position: sticky;
      top: 0;
    }
  }
}