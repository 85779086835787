#StatsSaApp {
    .container {
        border: 1px solid #eee;
        border-radius: 10px;
        height: fit-content;
        padding: 0px;
        background: #f7f7f7;

        #headerDiv {
            padding: 50px;
            background: #03427d;
            color: white;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;

            h2 {
                font: normal normal 500 35px/40px Roboto;
                margin: 0px;
                text-align: start;
                width: 100%;
            }

            h1 {
                display: none;

                @media only screen and (min-width: 1000px) {
                    display: flex;
                    font: normal normal 500 35px/40px Roboto;
                    margin: 0px;
                    width: fit-content;
                    white-space: nowrap;
                }
            }
            @media only screen and (min-width: 1000px) {
                margin: -20px -100px 0px -100px;
            }
        }

        #subheading {
            font: normal normal 500 18px/24px Roboto;
            width: 100%;
            text-align: center;
            padding: 50px 20px 30px 20px;
        }

        #buttonContainer {
            display: flex;
            justify-content: flex-end;
            padding: 30px 20px 50px 20px;
        }

        #submitButton {
            text-align: center;
            font: normal normal 500 16px/20px Roboto;
            width: 100%;
            min-width: fit-content;
            color: #FFFFFF;
            background: #03427d;
            border-radius: 4px;
            padding: 15px;
            cursor: pointer;

            @media only screen and (min-width: 1000px) {
                width: 30%;
            }
        }

        @media only screen and (min-width: 1000px) {
            padding: 20px 100px;
        }
    }

    .InspectionColumn {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0px 20px;
    }
    
    .InspectionRow {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;

        h3 {
            margin: 0px;
            font: normal normal 400 18px/24px Roboto;
            width: 100%;
            word-wrap: break-word;
            padding-bottom: 20px;

            @media only screen and (min-width: 1000px) {
                width: 70%;
                padding-bottom: 0px;
                padding-right: 50px;
            }
        }

        select {
            width: 100%;
            min-width: 200px;
            min-height: 40px;
            max-height: 40px;
            font: normal normal 400 14px/18px Roboto;
            text-align: center;
            background: #ececec;
            border: 1px solid lightgray;
            border-radius: 4px;
            cursor: pointer;

            @media only screen and (min-width: 1000px) {
                width: 30%;
            }
        }

        input {
            width: 100%;
            min-width: 200px;
            min-height: 40px;
            font: normal normal 400 14px/18px Roboto;
            background: #ececec;
            border: 1px solid lightgray;
            border-radius: 4px;
            cursor: pointer;

            @media only screen and (min-width: 1000px) {
                width: 30%;
            }
        }

        @media only screen and (min-width: 1000px) {
            flex-direction: row;
            align-items: unset;
        }
    }
}