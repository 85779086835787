
#ServestITApp {
  .MuiDataGrid {

    &-cell {
      font-size: 14px;
      color: rgb(100, 100, 100);
      text-align: left;
      display: flex;
      align-items: center;
      padding: {
        top: 5px;
        bottom: 5px;
      }
    }

    &-overlay {
      display: flex;
      align-items: flex-start;
    }

    &-colCell {
      display: flex;
      justify-content: center;
      border-color: white;
      border-style: solid;
      border-width: 3px;
    }
  }
}
