@import "../variables";

#JoburgWaterApp {
  #header {
    &Container {
      padding: clamp(1px, 1vw, 10px);
      padding: 15px;
    }

    &Title {
      font-size: 3.5vw;
      margin-bottom: 0;
    }

    &TitleCol,
    &LogoCol {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .App-logo {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    mix-blend-mode: multiply;
  }

  #portalNav {
    font-family: Gotham;
    font-weight: bold;
    background-color: $JoburgWaterColor !important;

    .dropdown a {
      color: white;
    }
    .dropdown-menu a {
      color: rgb(100, 100, 100) !important;
    }

    .link-item {
      margin: 0 20px 0 20px;
      color: white;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  #loginAlert {
    text-align: center;
    background-color: $JoburgWaterColor !important;
    border-color: $JoburgWaterColor !important;
    color: white !important;
    font-weight: bold;
  }
}
