#BotswanaTourismApp {
    #loginImage {
        position: fixed; 
        top: 0px; 
        left: 0px; 
        width: 100%; 
        height: 100%;
        overflow: hidden;
      }

    #loginImageFooter {
        position: absolute;
        bottom: 0px;
        right: 0px;
        padding: 20px;
        max-height: 90px;
        padding-left: 40px;
        padding-right:40px;
    }

    #loginTextFooter {
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 20px;
        padding-left: 40px;
        padding-right:40px;
        max-height: 100px;
        color:white;
    }

    #loginGradient {
        background-image: linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.70));
        position: fixed; 
        top: 0px; 
        left: 0px; 
        width: 100%; 
        height: 100%;
        overflow: hidden;
    }
    #loginFormContainer {
        margin-top: 20vh;
    } 
    #loginImageSub {
        margin-bottom:25px;
    }
    #loginFormCard{
        padding:30px;
        border-radius: 1rem;
        padding-bottom:0px;
    }
    #loginSubHeading{
        font-size: 1.3rem;
    }    
    #forgotPass{
        text-align: initial !important;
    }
    #loginButton{
        width: 70%;
    }
}
body > .loginComponent {
    overflow: hidden;
}