#SyntellApp {
  .iconGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw;
  }

  .iconRow {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .ui.link.card {
    height: 100%;

    .header {
      font-size: large;
    }

    .description {
      color: black;
      font-size: medium;
    }
  }
}
