#BotswanaTourismApp {
  .navbar-nav a {
    color: rgba(0,0,0,0.6);
    text-decoration: none;
    font-weight: normal;
    padding-left: 40px;
    padding-right: 15px;
    font: caption;
    margin-top: auto;
    margin: auto;
    font-size: 1.4rem;
  }

  #headerContainer {
    padding: clamp(1px, 1vw, 10px);
    padding: 15px;
  }

  #headerTitle {
    font-size: 30px;
    margin-bottom: 0;
  }

  .textBoldUnderLineColour {
    font-weight: bold !important;
    text-decoration: underline !important;
    color: rgba(0,0,0,0.8) !important ;
  }

  #headerText {
    color: white;
    text-align: center;
    font-size: 2.5vw;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    text-shadow: 1px 2px 2px #000000;
  }

  #headerSubText {
    color: white;
    text-align: center;
    font-size: 1vw;
    font-weight: normal;
    text-shadow: 1px 2px 2px #000000;
  }


  #headerTitleCol,
  #headerLogoCol {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .App-logo {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    mix-blend-mode: multiply;
  }

  #portalNav {
    font-family: GothamBlack;
    background-color: rgba(255,255,255,0.5);
    position: absolute;
    top: 0px;
    z-index: 10;
    width: 100vw;
  }

  #navbarImage {
    height: 55px;
    padding:5px;
    padding-right: 60px;
  }

  #navbarBackgroundImageContainer{
    position: relative;
    text-align: center;
  }

  #navbarBackgroundImage{
    width:100%;
    z-index: -1;  
    filter: brightness(60%);
  }

  #portalNav .dropdown-item {
    font-family: Gotham !important;
  }

  #portalNav .nav-link {
    margin: 0 20px 0 20px;
    color: white;
  }

  #forcelinkFooter {
    display: flex;
    justify-content: flex-end;
  }

  #forcelinkFooter .navbar-brand {
    height: 5vh;
  }

  #forcelinkFooter img {
    max-width: 100%;
    max-height: 100%;
  }

  #headerTextImage {
    max-width: 100%;
  }
}