#BotswanaTourismApp {
  #screenWidthToast {
    font-size: clamp(12px, 1vw, 32px);
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .screenWidthToast,
  .screenWidthToast > div {
    color: black;
    padding: clamp(1px, 0.5vw, 3px) !important;
    margin: 0 !important;
    z-index: 99;
  }

  #App {
    margin: 2vw;
  }

  #AppContainer {
    margin-bottom: 25px;
    padding-bottom: 0px;
  }
  .formLabels {
    margin-bottom: 0rem;
    margin-top: 1rem;
  }

  .ficardbody{
    padding-top: 0px;
  }

  #LoadingOverlay {
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-color: gray;
    z-index: 100;
    opacity: 0.7;
    transition: 2s;
    text-align: center;
    line-height: 100vh;
    font-size: 3em;
    color: white;
  }

  #LoadingOverlayContainer {
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: 100;
    text-align: center;
    line-height: 100vh;
  }

  #LoadingOverlayImage {
    height: 200px;
    z-index: 1000;
    position: relative;
  }


  @keyframes fade {
    0%,100% { opacity: 0.4 }
    50% { opacity: 0.7 }
  }
}