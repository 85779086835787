@import "../variables";

#ServestITApp, #BedworthApp {
  #createTicketForm {
    .login-form .form-text {
      text-align: center;
      font-weight: bold;
      font-size: clamp(10px, 2vw, 18px);
    }

    #formCard {
      border-color: $offGrey;
      border-width: 2px;
    }

    #formHeader {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media screen and (min-width: $lg) {
      #formBody {
        padding: 2vw 8vw 2vw 8vw;
      }
    }

    #finalButtons {
      display: flex;
      justify-content: space-around;
    }

    .formInputs {
      display: flex;
      justify-content: space-around;
    }

    .formLabels {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0;
    }

    .largeLabel {
      font-size: larger;
    }

    .smallLabel {
      font-size: small;
    }

    #addressAlert {
      color: red;
      text-align: left;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
