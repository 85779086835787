#BedworthApp {
  #loginFormContainer {
    margin: 10% auto 0 auto !important;
    max-width: 900px;
    display: flex;
    box-shadow: 0 3px 20px 0px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 3px 20px 0px rgb(0 0 0 / 50%);
    -o-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.5);
    flex-direction: column;
    @media screen and (min-width: 992px) {
      flex-direction: row;
    }
  }
}

.new-login-logo-container {
  display: none;
  margin: 0;
  padding: 0;
  flex: 0 0 50%;
  background: white !important;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 992px) {
    display: flex;
  }
}

.new-login-form-container {
  margin: 0;
  padding: 0;
  flex: 0 0 50%;
  background: rgba(0, 0, 0, 0.5);
}

.new-login-logo-image {
  width: 80%;
}

.new-login-title {
  font-size: 30px;
}