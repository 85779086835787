#ServestITApp, #BedworthApp {
  .iconGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw;
  }

  .servest-responsive-text {
    padding-left: 0 !important;
    @media only screen and (min-width: 1200px) {
      padding-left: 7rem !important;
    }
  }

  .servest-responsive-row {
    margin-left: 0 !important;
    @media only screen and (min-width: 1200px) {
      margin-left: 7rem !important;
    }
  }

  .servest-title {
    font-size: 25px;
    @media only screen and (min-width: 1200px) {
      font-size: 40px;
    }
  }

  .servest-subtitle {
    font-size: 15px;
    @media only screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }

  .landingCardWhite {
    display: flex;
    background: #133c98;
    text-align: center;
    fontFamily: "roboto,sans-serif";
    color: white;
    min-width: 15rem;
    min-height: 15rem;
    max-height: 25rem;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    @media only screen and (min-width: 1200px) {
      height: 25rem;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
      background-size: 130%;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);

      .card-img-overlay {
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
        background: rgba(100, 100, 100, 0.5) ;
        background: linear-gradient(
                        0deg,
                        rgba(100, 100, 100, 0.5) 0%,
                        rgb(104, 104, 104) 100%
        );
      }
    }
  }

  .hoverCard {
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
      background-size: 130%;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);

      .card-img-overlay {
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
        background: rgba(100, 100, 100, 0.5) ;
        background: linear-gradient(
                        0deg,
                        rgba(100, 100, 100, 0.5) 0%,
                        rgb(104, 104, 104) 100%
        );
      }
    }
  }

  .landingCardBlue {
    display: flex;
    fontFamily: "roboto,sans-serif";
    text-align: center;
    background: white;
    color: #133c98;
    width: 20rem;
    height: 25rem;
    border: 2px solid #133c98;
  }

  .iconRow {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .iconRow2 {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .ui.link.card {
    height: 100%;

    .header {
      font-size: large;
    }

    .description {
      color: black;
      font-size: medium;
    }
  }
}
