#SSGFacilitiesApp #loginFormContainer {
  width: 100%;
  display: flex;
  box-shadow: 0 3px 20px 0px rgb(0 0 0 / 50%);
  -moz-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 3px 20px 0px rgb(0 0 0 / 50%);
  -o-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.5);
  flex-direction: column;
  padding: 40px 24px;
  gap: 40px;

  @media screen and (min-width: 700px) {
    width: 600px
  }

  .new-login-logo-container {
    display: none;
    margin: 0;
    padding: 0;
    flex: 0 0 50%;
    background: white !important;
    justify-content: center;
    align-items: center;
  
    @media screen and (min-width: 992px) {
      display: flex;
    }
  }
  
  .new-login-form-container {
    margin: 0;
    padding: 0;
    background-color: #ffffff !important;
  }
  
  .new-login-logo-image {
    width: 80px !important;
  }

  .new-login-title {
    font-size: 30px;
  }  

  #loginFormCard {
    display: flex;
    flex-direction: column;
    gap: 40px;
  
    .card-body {
      padding: 0px;
      display: flex;
      flex-direction: column;
      gap: 16px;
  
      .MuiInputBase-input {
        padding: 12px 16px;
        line-height: 21px;
        height: 21px;
      }
  
      fieldset {
        top: 0px
      }
    }
  }
  
  .card-footer {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0px;
  
    a {
      color: #E76625 !important;
    }
  }
}
