#SiemensApp {
  .iconRow {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 1vw 2vw;
    margin-bottom: 1vw;
  }

  .ui.link.card {
    .header {
      font-size: large;
    }

    .description {
      color: black;
      font-size: medium;
    }
  }
}
